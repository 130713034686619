<script>
import { mapGetters } from 'vuex'

import PageSubmenu from '@/components/PageSubmenu.vue'

export default {
  components: { PageSubmenu },
  computed: {
    ...mapGetters(['domain']),
    links () {
      const links = [
        {
          label: 'Actualités',
          path: '/app/articles',
          roles: ['admin', 'director', 'community-manager']
        },
        {
          label: 'Média droit social',
          path: '/app/articles/universals',
          roles: ['admin', 'director', 'community-manager']
        },
        this.domain?.slug !== 'media-mvp' && {
          label: `Média salarié`,
          path: '/app/articles/media-mvp',
          roles: ['admin', 'director', 'community-manager']
        }
      ]
      return links
    }
  },
  created () {

  }
}
</script>

<template>
  <div>
    <PageSubmenu :links="links" />
  </div>
</template>
